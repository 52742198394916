import { useEffect } from 'react';

import { logExperimentChokepoint } from '../../logMiniEvent';

import type { ChokePointEmitterProps } from '@skyscanner-internal/falcon-shared-types/types/ChokePointEmitter';

// eslint-disable-next-line import/prefer-default-export
export const ChokePointEmitter = ({ chokepoints }: ChokePointEmitterProps) => {
  useEffect(() => {
    chokepoints.forEach((chokepoint) => {
      logExperimentChokepoint(chokepoint);
    });
  }, [chokepoints]);

  return null;
};
